import React from "react";
import Footer from "../../components/Footer/Footer";
import EventsTab from "../../components/EventsTab/EventsTabs";
import ScrollTopBtn from "../../components/UI/ScrollTopBtn";

const Events = () => {
    return (
        <div>
            <ScrollTopBtn />
            <EventsTab />
            <Footer />
        </div>
    )
}
export default Events
