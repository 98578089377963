import React, {useEffect, useState} from "react";
import * as styles from "./styles.module.scss";
import EventItem from "./EventItem/EventItem";
import {Link} from "gatsby";
import FadeInSection from "../FadeInSection/FadeInSection";
import SliderMoreBtn from "../UI/SliderMoreBtn";
import {useContextState} from "../../context/store";
import {getEventsList} from "../../helpers/getEventList";
import axios from "../../api/Api";


const EventList = () => {
    const {state} = useContextState()
    const [events, setEvents] = useState([])
    useEffect(() => {
        setEvents(getEventsList(state.events))
    }, [state])
    const handleClick = () => {
        axios.get('/events/?page=2').then(res => setEvents(events.concat(res.data[0])))
    }
    console.log(state)
    return (
        <div>{events?.length >= 1 ? <div className={styles.event_list_container}>
            {events?.map(event => {
                return <Link className={styles.event_container} to={`/event/${event.code}`}>
                    <FadeInSection>
                        <EventItem event={event}/>
                    </FadeInSection>
                </Link>
            })}
        </div> : <div className={styles.is_events_miss}>
            <form action="">
                <h3>We are preparing events for you.</h3>
                <p>Come back tomorrow or subscribe for updates.</p>
                <div className={styles.is_events_miss_input}>
                    <input type="text" placeholder="Enter e-mail"/>
                    <SliderMoreBtn>Subscribe </SliderMoreBtn>
                </div>
            </form>
        </div>}
            {/*<button onClick={handleClick}>Setfsaf</button>*/}
        </div>
    )
}
export default EventList
