import React, {useEffect, useState} from "react";
import GoogleMapReact from 'google-map-react';
import MapMark from "./MapMark";
import * as styles from './styles.module.scss'
import {useContextState} from "../../context/store";
import {getEventsList} from "../../helpers/getEventList";

const EventsMap = () => {
    const {state} = useContextState()
    const [events, setEvents] = useState([])

    useEffect(() => {
        setEvents(getEventsList(state.events))
    }, [state])
    console.log(events)
    const defaultProps = {
        center: {
            lat: 49.7329916,
            lng: 29.6665906,
        },
        zoom: 1
    };

    const coordinateParse = (string) => {
        return string?.split(', ')
    }

    return (
        // Important! Always set the container height explicitly
        <div className={styles.map_wrapper}>
            <div className={styles.map}>
                <GoogleMapReact
                    bootstrapURLKeys={{key: 'AIzaSyBBwASLvDIX5EHaL7BJkbD1RxpIdu5fqUs'}}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}>

                    {events.map(event => {
                        return <MapMark lat={coordinateParse(event.location_coord)[0]}
                                        lng={coordinateParse(event.location_coord)[1]}
                                        title={event.title}
                                        place={event.location}
                                        dateStart={event.date_start}
                                        dateEnd={event.date_end}
                        />
                    })}

                </GoogleMapReact>
            </div>
        </div>
    );
}
export default EventsMap
