import React from "react";
import * as styles from './styles.module.scss'
import ItemMapMark from "../../assets/Events/ItemMapMark";

const MapMark = ({$hover, place, dateStart, dateEnd, title}) => {
    return <div className={styles.map_mark}>
        <div className={styles.inner_mark}></div>
        <div className={styles.outer_mark}></div>
        <div className={`${$hover ? styles.show_info : styles.hide_info}`}>
            <div className={styles.place_container}>
                <ItemMapMark/>
                <p>{place}</p>
            </div>
            <p className={styles.date}>{dateStart} - {dateEnd}</p>
            <h4>{title}</h4>
        </div>
    </div>
}
export default MapMark
